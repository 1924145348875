<template>
  <el-dialog
    :visible.sync="dialogVisible"
    top="5%"
    width="1200px"
    :center="true"
    title="新增讲师"
  >
    <div style="height: 500px">
      <div class="ovy-a">
        <div class="addlist">
          <div>
            <h3>
              <span>基本信息</span>
            </h3>
            <div class="form-box">
              <el-form
                ref="baseform"
                label-width="7rem"
                class="forms"
                :rules="rules"
                :model="baseform"
                :disabled="disabled"
              >
                <el-form-item label="讲师姓名" prop="teacherName">
                  <el-input v-model="baseform.teacherName"></el-input>
                </el-form-item>
                <el-form-item label="身份证号" prop="teacherIdcard">
                  <el-input
                    v-model="baseform.teacherIdcard"
                    @input="
                      (val) => {
                        getIdcard(val);
                      }
                    "
                  ></el-input>
                  <div @click="showInfo" v-show="showtip" class="tip">
                    {{ idcard || "" }}
                  </div>
                </el-form-item>
                <el-form-item label="手机号" prop="teacherPhoneNumber">
                  <el-input v-model="baseform.teacherPhoneNumber"></el-input>
                </el-form-item>
                <el-form-item label="身份证照片" required>
                  <el-form-item prop="sfzZjy" class="tp"
                    ><el-upload
                      :on-change="upSfzZ"
                      :before-upload="beforeAvatarUpload2"
                      :http-request="$requestMine"
                      :show-file-list="false"
                      class="img-el-upload"
                      action
                      accept="image/png, image/gif, image/jpg, image/jpeg"
                      ><el-image
                        v-if="!baseform.idCardFront"
                        :src="require('@/assets/morenimg/zimg1.png')"
                        fit="contain"
                        class="imgCenter imgCenter1"
                      ></el-image>
                      <el-image
                        v-else
                        :src="baseform.idCardFront"
                        fit="contain"
                        class="imgCenter"
                      ></el-image>
                    </el-upload>
                    <div class="tswz">
                      <p class="t1">身份证正面</p>
                      <p>图片大小不超过5M</p>
                      <p>图片分辨率不低于72dpi</p>
                      <el-popover placement="right" trigger="hover">
                        <img src="@/assets/morenimg/zimg2.png" alt="" />
                        <el-button type="text" slot="reference"
                          >查看示例</el-button
                        >
                      </el-popover>
                    </div>
                  </el-form-item>
                  <el-form-item prop="sfzFjy" class="tp"
                    ><el-upload
                      :on-change="upSfzF"
                      :before-upload="beforeAvatarUpload2"
                      :http-request="$requestMine"
                      :show-file-list="false"
                      class="img-el-upload"
                      action
                      accept="image/png, image/gif, image/jpg, image/jpeg"
                      ><el-image
                        v-if="!baseform.idCardBack"
                        :src="
                          baseform.idCardBack ||
                          require('@/assets/morenimg/zimg1.png')
                        "
                        fit="contain"
                        class="imgCenter imgCenter1"
                      ></el-image>
                      <el-image
                        v-else
                        :src="baseform.idCardBack"
                        fit="contain"
                        class="imgCenter"
                      ></el-image>
                    </el-upload>
                    <div class="tswz">
                      <p class="t1">身份证反面</p>
                      <p>图片大小不超过5M</p>
                      <p>图片分辨率不低于72dpi</p>
                      <el-popover placement="right" trigger="hover">
                        <img src="@/assets/morenimg/zimg3.png" alt="" />
                        <el-button type="text" slot="reference"
                          >查看示例</el-button
                        >
                      </el-popover>
                    </div>
                  </el-form-item>
                </el-form-item>
                <el-form-item label="毕业证书">
                  <el-form-item prop="byzsjy" class="tp"
                    ><el-upload
                      :on-change="upbyzs"
                      :before-upload="beforeAvatarUpload2"
                      :http-request="$requestMine"
                      :show-file-list="false"
                      class="img-el-upload"
                      action
                      accept="image/png, image/gif, image/jpg, image/jpeg"
                      ><el-image
                        v-if="!baseform.graduationCertificate"
                        :src="require('@/assets/morenimg/zimg1.png')"
                        fit="contain"
                        class="imgCenter imgCenter1"
                      ></el-image>
                      <el-image
                        v-else
                        :src="baseform.graduationCertificate"
                        fit="contain"
                        class="imgCenter"
                      ></el-image>
                    </el-upload>
                    <div class="tswz">
                      <p class="t1">毕业证书</p>
                      <p>图片大小不超过5M</p>
                      <p>图片分辨率不低于72dpi</p>
                      <el-popover placement="right" trigger="hover">
                        <img src="@/assets/morenimg/zimg4.png" alt="" />
                        <el-button type="text" slot="reference"
                          >查看示例</el-button
                        >
                      </el-popover>
                    </div>
                  </el-form-item>
                  <el-form-item class="tp"
                    ><el-upload
                      :on-change="upxwz"
                      :before-upload="beforeAvatarUpload2"
                      :http-request="$requestMine"
                      :show-file-list="false"
                      class="img-el-upload"
                      action
                      accept="image/png, image/gif, image/jpg, image/jpeg"
                      ><el-image
                        v-if="!baseform.degreeCertificate"
                        :src="require('@/assets/morenimg/zimg1.png')"
                        fit="contain"
                        class="imgCenter imgCenter1"
                      ></el-image>
                      <el-image
                        v-else
                        :src="baseform.degreeCertificate"
                        fit="contain"
                        class="imgCenter"
                      ></el-image>
                    </el-upload>
                    <div class="tswz">
                      <p class="t1">学位证(非必填)</p>
                      <p>图片大小不超过5M</p>
                      <p>图片分辨率不低于72dpi</p>
                      <el-popover placement="right" trigger="hover">
                        <img src="@/assets/morenimg/zimg5.png" alt="" />
                        <el-button type="text" slot="reference"
                          >查看示例</el-button
                        >
                      </el-popover>
                    </div>
                  </el-form-item>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div class="certificate-box">
            <h3>
              <span>证书信息</span>
            </h3>
            <div
              class="df card-border form-box"
              v-for="(item, index) in addlist"
              :key="index"
            >
              <el-form
                :model="item"
                ref="cardform"
                label-width="7rem"
                class="forms form-bg"
              >
                <el-form-item
                  label="资质类型"
                  prop="qualificationTypeId"
                  :rules="[
                    {
                      required: true,
                      trigger: ['blur'],
                      message: '请选择资质类型',
                    },
                  ]"
                >
                  <el-select
                    v-model="item.qualificationTypeId"
                    placeholder="请选择"
                    @change="
                      (val) => {
                        changeQualification(val, index);
                      }
                    "
                  >
                    <el-option
                      v-for="el in Qualification"
                      :key="el.qualificationTypeId"
                      :label="el.name"
                      :value="el.qualificationTypeId"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="职业证书编号"
                  prop="certificateNo"
                  :rules="[
                    {
                      required: true,
                      trigger: 'blur',
                      message: '请输入证书编号',
                    },
                  ]"
                >
                  <el-input v-model="item.certificateNo"></el-input>
                </el-form-item>
                <el-form-item
                  label="是否永久有效"
                  prop="qualificationIsForever"
                  :rules="[
                    {
                      required: true,
                      trigger: ['blur', 'change'],
                      message: '请选择资质类型',
                    },
                  ]"
                >
                  <el-select
                    :disabled="item.disabled"
                    v-model="item.qualificationIsForever"
                    placeholder="请选择"
                    @change="
                      (val) => {
                        changeCertificateNo(val, index);
                      }
                    "
                  >
                    <el-option label="是" :value="true"></el-option>
                    <el-option label="否" :value="false"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  v-if="!item.qualificationIsForever"
                  label="证书有效期"
                  prop="date"
                  :rules="[
                    {
                      required: true,
                      validator: $validateTime,
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-date-picker
                    v-model="item.date"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="timestamp"
                    @change="
                      (val) => {
                        changeTime(val, index);
                      }
                    "
                  ></el-date-picker>
                </el-form-item>
                <el-form-item
                  v-else
                  label="发证日期"
                  prop="qualificationStartDateMillis"
                  :rules="[
                    {
                      required: true,
                      message: '请输入发证日期',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-date-picker
                    v-model="item.qualificationStartDateMillis"
                    type="date"
                    value-format="timestamp"
                    @change="
                      (val) => {
                        changeTime1(val, index);
                      }
                    "
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="证书图片">
                  <el-upload
                    action
                    list-type="picture-card"
                    :auto-upload="false"
                    :on-change="
                      (file, fileList) => {
                        handleAvatarSuccess2(file, fileList, index);
                      }
                    "
                    :file-list="item.certPhotoDTOS"
                    :before-upload="beforeAvatarUpload2"
                    :http-request="$requestMine"
                    accept="image/png, image/gif, image/jpg, image/jpeg"
                  >
                    <i slot="default" class="el-icon-plus"></i>
                    <div slot="file" slot-scope="{ file }">
                      <img
                        class="el-upload-list__item-thumbnail"
                        :src="file.url"
                        alt=""
                      />
                      <span class="el-upload-list__item-actions">
                        <span
                          class="el-upload-list__item-preview"
                          @click="
                            (file) => {
                              handlePictureCardPreview(file, index);
                            }
                          "
                        >
                          <i class="el-icon-zoom-in"></i>
                        </span>
                        <span
                          class="el-upload-list__item-delete"
                          @click="handleRemove(file, index)"
                        >
                          <i class="el-icon-delete"></i>
                        </span>
                      </span>
                    </div>
                  </el-upload>
                </el-form-item>
              </el-form>
              <div class="df zsBtn">
                <el-button
                  v-if="index != 0"
                  @click="delCertificate(index)"
                  class="bgc-bv"
                  >删除</el-button
                >
                <el-button
                  v-if="index == addlist.length - 1 && index != 9"
                  @click="certificate"
                  class="bgc-bv"
                  >添加证书</el-button
                >
              </div>
            </div>
          </div>
          <div class="btn-box">
            <el-button @click="doCancel" class="bgc-bv">取 消</el-button>
            <el-button class="bgc-bv" @click="doAddSave">确 定</el-button>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "addLecturer",
  components: {},
  data() {
    // 上传身份证正面校验
    let sfzZ = (rule, value, callback) => {
      if (!this.baseform.idCardFront) {
        callback(new Error("请上传身份证正面照片!"));
      } else {
        callback();
      }
    };
    // 上传身份证反面校验
    let sfzF = (rule, value, callback) => {
      if (!this.baseform.idCardBack) {
        callback(new Error("请上传身份证反面照片!"));
      } else {
        callback();
      }
    };
    var Phone = (rule, value, callback) => {
      if (!value) {
        callback();
      } else if (
        value &&
        !/^1[3456789]\d{9}$/.test(value) &&
        !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      showtip: false,
      idcard: "",
      idcardObj: {}, // 老师详情信息()
      disabled: false,
      title: "新增讲师",
      teacherId: "", // 老师di
      addlist: [], // 老师资质列表
      addobj: {
        certificateNo: "", //职业证书编号
        qualificationEndDateMillis: "", // 资质有效期-结束日期，毫秒
        qualificationStartDateMillis: "", // 资质有效期-起始日期，毫秒
        qualificationTypeId: "", // 资质类型id
        date: "", // 证书有效期
        qualificationIsForever: false, // 是否永久有效
        certPhotoDTOS: [], // 证书图片
      },
      Qualification: [], //资质类型,
      // 基本信息
      baseform: {
        teacherIdcard: "",
        teacherName: "",
        teacherPhoneNumber: "",
        idCardFront: "",
        idCardFrontKey: "",
        idCardFrontNoWatermark: "",
        idCardBack: "",
        idCardBackKey: "",
        idCardBackNoWatermark: "",
        graduationCertificate: "",
        graduationCertificateKey: "",
        graduationCertificateNoWatermark: "",
        degreeCertificate: "",
        degreeCertificateKey: "",
        degreeCertificateNoWatermark: "",
      },
      rules: {
        teacherName: [
          { required: true, trigger: "blur", message: "请输入姓名" },
        ],
        teacherIdcard: [
          { required: true, validator: this.$validateIdCard, trigger: "blur" },
        ],
        teacherPhoneNumber: [
          {
            required: false,
            validator: Phone,
            trigger: "blur",
          },
        ],
        sfzZjy: [{ required: true, validator: sfzZ, trigger: "change" }],
        sfzFjy: [{ required: true, validator: sfzF, trigger: "change" }],
      },
      delIds: [],
    };
  },
  created() {
    this.getQualification();
    this.init();
  },
  computed: {},
  methods: {
    init() {
      this.title = "新增讲师";
      this.addlist.push({ ...this.addobj });
    },
    getIdcard(newValue) {
      if (newValue.length == 18) {
        this.$post("/biz/teacher/queryByTeacherCardId", {
          teacherIdcard: this.baseform.teacherIdcard,
        })
          .then((res) => {
            if (res.data.createId) {
              this.idcardObj = res.data;
              this.idcard =
                res.data.teacherName +
                "/" +
                res.data.teacherIdcard +
                (res.data.teacherPhoneNumber
                  ? "/" + res.data.teacherPhoneNumber
                  : "");
              this.showtip = true;
              if (this.idcardObj.teacherId) {
                this.queryData();
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.showtip = false;
        // this.$refs.baseform.resetFields();
      }
    },
    // 获取详情
    queryData() {
      this.$post("/biz/teacher/querySingleTeacher", {
        teacherId: this.idcardObj.teacherId,
      })
        .then((res) => {
          const data = res.data || {};
          this.baseform = {
            ...this.baseform,
            teacherName: data.teacherName,
            teacherIdcard: data.teacherIdcard,
            teacherPhoneNumber: data.teacherPhoneNumber,
            idCardFront:
              data.idCardFrontUrl == "default"
                ? require("@/assets/morenimg/zimg6.png")
                : data.idCardFrontUrl,
            idCardFrontKey: data.idCardFront,
            idCardBack:
              data.idCardBackUrl == "default"
                ? require("@/assets/morenimg/zimg7.png")
                : data.idCardBackUrl,
            idCardBackKey: data.idCardBack,
            graduationCertificate:
              data.graduationCertificateUrl == "default"
                ? require("@/assets/morenimg/zimg8.png")
                : data.graduationCertificateUrl,
            graduationCertificateKey: data.graduationCertificate,
            degreeCertificate:
              data.degreeCertificateUrl == "default"
                ? require("@/assets/morenimg/zimg9.png")
                : data.degreeCertificateUrl,
            degreeCertificateKey: data.degreeCertificate,
          };
          const teacherQualifications = data.teacherQualifications;
          if(data.teacherQualifications.length){
            teacherQualifications.map((item) => {
              item.date = [
                item.qualificationStartDateMillis,
                item.qualificationEndDateMillis,
              ];
              item.certPhotoBOS.map((item1) => {
                item1.url = item1.photoeUrl;
              });
              item.certPhotoDTOS = item.certPhotoBOS;
              return item;
            });
            this.addlist = teacherQualifications;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showInfo() {
      this.showtip = false;
      this.baseform.teacherPhoneNumber = this.idcardObj.teacherPhoneNumber;
      this.baseform.teacherIdcard = this.idcardObj.teacherIdcard;
      this.baseform.teacherName = this.idcardObj.teacherName;
      if (this.idcardObj.teacherQualifications.length > 0) {
        this.$message({
          type: "warning",
          message: "该讲师已经存在机构",
        });
      }
    },
    // 查询资质了类型
    getQualification() {
      this.$post("/biz/qualificationType/listAll")
        .then((res) => {
          const resData = res.data || [];
          this.Qualification = resData;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 选择资质
    changeQualification(val, ind) {
      console.log(val);
      this.addlist[ind].qualificationTypeId = val;
    },
    changeCertificateNo(val, ind) {
      console.log(val);
      this.addlist[ind].qualificationIsForever = val;
    },
    // 时间
    changeTime(val, index) {
      this.addlist[index].qualificationStartDateMillis = val[0] || val;
      this.addlist[index].qualificationEndDateMillis = val[1] || val;
    },
    // 时间
    changeTime1(val, index) {
      this.addlist[index].qualificationStartDateMillis = val;
    },
    // 新增 -- 证书
    certificate() {
      if (this.addlist.length == 10) {
        return;
      } else {
        this.addlist.push({
          ...this.addobj,
          certPhotoDTOS: [],
        });
      }
    },
    // 删除 -- 证书
    delCertificate(ind) {
      if (this.addlist[ind].teacherId) {
        this.delIds.push({
          teacherQualificationId: this.addlist[ind].teacherQualificationId,
        });
      }
      this.addlist.splice(ind, 1);
    },
    // 上传证书照片
    handleAvatarSuccess2(file, fileList, index) {
      const fileType = file.raw.name.slice(
        file.raw.name.lastIndexOf(".") + 1,
        file.raw.name.length
      );
      let formData = new FormData();
      formData.append("file", file.raw);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      this.$Postformat("/sys/upload/watermark", formData).then((res) => {
        this.addlist[index].certPhotoDTOS.push({
          photoKey: res.data.fileKey || "", // 证书图片key
          originalPhotoKey: res.data.fileKeyNoWatermark || "", // 无水印证书图片key
          url: res.data.fileURL,
        });
      });
    },
    // 上传证书照片 - 预览
    handlePictureCardPreview(file, index) {
      console.log(file, index);
    },
    // 上传证书照片 - 删除
    handleRemove(file, index) {
      for (let i = 0; i < this.addlist[index].certPhotoDTOS.length; i++) {
        if (this.addlist[index].certPhotoDTOS[i].photoKey == file.photoKey) {
          this.addlist[index].certPhotoDTOS.splice(i, 1);
        }
      }
    },
    check() {
      const teachers = {};
      let stu = true;
      this.addlist.forEach((item, index) => {
        if (!teachers[item.certificateNo]) {
          teachers[item.certificateNo] = [];
        }
        teachers[item.certificateNo].push(index);
      });
      for (const key in teachers) {
        const element = teachers[key];
        element.forEach(() => {
          if (element.length > 1) {
            stu = false;
          }
        });
      }
      if (!stu) {
        this.$message({
          type: "warning",
          message: "证书号不能重复",
        });
      }
      return stu;
    },
    check1() {
      const cardform = this.$refs["cardform"];
      let stu = true;
      for (let i = 0; i < cardform.length; i++) {
        cardform[i].validate((valid1) => {
          if (valid1 == false) {
            stu = false;
          }
        });
      }
      return stu;
    },
    // 新增&编辑
    doAddSave() {
      let url = "/biz/teacher/insert";
      const params = {
        teacherName: this.baseform.teacherName,
        teacherPhoneNumber: this.baseform.teacherPhoneNumber,
        teacherIdcard: this.baseform.teacherIdcard,
        teacherQualifications: this.addlist,
        idCardFront: this.baseform.idCardFrontKey,
        idCardFrontNoWatermark: this.baseform.idCardFrontNoWatermark,
        idCardBack: this.baseform.idCardBackKey,
        idCardBackNoWatermark: this.baseform.idCardBackNoWatermark,
      };
      if (this.baseform.graduationCertificate) {
        params.graduationCertificate = this.baseform.graduationCertificateKey;
        params.graduationCertificateNoWatermark =
          this.baseform.graduationCertificateNoWatermark;
      }
      if (this.baseform.degreeCertificateKey) {
        params.degreeCertificate = this.baseform.degreeCertificateKey;
        params.degreeCertificateNoWatermark =
          this.baseform.degreeCertificateNoWatermark;
      }
      // 有老师id - 编辑
      if (this.idcardObj.teacherId) {
        url = "/biz/teacher/update";
        params.teacherId = this.idcardObj.teacherId;
        const addArr = [];
        const editArr = [];
        for (let i = 0; i < this.addlist.length; i++) {
          if (!this.addlist[i].teacherId) {
            addArr.push(this.addlist[i]);
          } else {
            editArr.push(this.addlist[i]);
          }
        }
        params.modifyTeacherQualifications = editArr;
        params.deleteTeacherQualifications = this.delIds;
        params.addTeacherQualifications = addArr;
      }
      const baseform = this.$refs["baseform"];
      baseform.validate((valid) => {
        if (valid) {
          if (this.check1()) {
            if (this.check()) {
              this.$post(url, params)
                .then((res) => {
                  this.dialogVisible = false;
                  this.baseform = this.$options.data().baseform;
                  this.addlist = [];
                  this.addlist.push({ ...this.addobj, certPhotoDTOS: [] });
                  if (res.status == 0) {
                    if(this.idcardObj.teacherId){
                      this.$message({
                        type: "success",
                        message: res.message + '，请重新选择讲师',
                      });
                    } else {
                      this.$message({
                        type: "success",
                        message: res.message,
                      });
                    }
                    this.$emit("getList");
                    this.$emit("childback1", res.data.teacherQualificationId);
                  } else if (res.status == 1) {
                    this.$message({
                      type: "warning",
                      message: "该讲师已经存在机构",
                    });
                  }
                })
                .catch((err) => {
                  return;
                  console.log(err);
                });
            }
          }
        }
      });
    },
    doCancel() {
      this.dialogVisible = false;
    },
    showPopUp() {
      this.dialogVisible = true;
    },
    // 上传身份证正面
    upSfzZ(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload/watermark", formData)
          .then((result) => {
            this.baseform.idCardFront = result.data.fileURL || "";
            this.baseform.idCardFrontKey = result.data.fileKey || "";
            this.baseform.idCardFrontNoWatermark =
              result.data.fileKeyNoWatermark || "";
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    // 上传身份证反面
    upSfzF(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload/watermark", formData)
          .then((result) => {
            this.baseform.idCardBack = result.data.fileURL || "";
            this.baseform.idCardBackKey = result.data.fileKey || "";
            this.baseform.idCardBackNoWatermark =
              result.data.fileKeyNoWatermark || "";
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    // 上传毕业证书
    upbyzs(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload/watermark", formData)
          .then((result) => {
            this.baseform.graduationCertificate = result.data.fileURL || "";
            this.baseform.graduationCertificateKey = result.data.fileKey || "";
            this.baseform.graduationCertificateNoWatermark =
              result.data.fileKeyNoWatermark || "";
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    // 上传学位证
    upxwz(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload/watermark", formData)
          .then((result) => {
            this.baseform.degreeCertificate = result.data.fileURL || "";
            this.baseform.degreeCertificateKey = result.data.fileKey || "";
            this.baseform.degreeCertificateNoWatermark =
              result.data.fileKeyNoWatermark || "";
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
  },
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
<style lang="less">
.addlist {
  h3 {
    display: flex;

    &::before {
      content: "";
      border-left: 3px solid rgb(92, 107, 232);
      height: 1rem;
      top: 0.15rem;
      position: relative;
    }

    span {
      margin-left: 20px;
    }

    margin-bottom: 30px;
  }
}

.zsBtn {
  margin-left: 20px;

  button {
    height: 40px;
  }
}

.certificate-box {
  > div {
    margin-bottom: 5px;
  }

  > div:last-child {
    border: 0;
    margin-bottom: 0;
  }
}

.form-box {
  padding: 10px 20px;

  .forms {
    width: 800px !important;
    padding: 20px;

    .el-form-item__content {
      width: 100%;
    }
  }

  .form-bg {
    background-color: rgb(247, 248, 253);

    .el-form-item__content {
      width: 80%;
    }
  }
}

.btn-box {
  padding: 20px 20%;
  display: flex;
  justify-content: space-around;
  width: 70%;

  .el-button {
    width: 10rem;
  }
}

.tp {
  width: 50%;
  float: left;

  .img-el-upload {
    width: 63%;
    float: left;
    margin-right: 2%;
  }

  .tswz {
    float: left;
    font-size: 12px;
    line-height: 25px;
    color: #909399;

    .t1 {
      font-size: 14px;
      line-height: 40px;
      color: #606266;
    }
  }
}

.tip {
  position: relative;
  z-index: 100;
  width: 400px;
  background-color: #fff;
  box-shadow: 0 0 10px #eee;
  white-space: nowrap;
}
</style>
